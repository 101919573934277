import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import cn from 'classnames';
import axios from 'axios';
import { ProgressContainer } from '../containers/ProgressContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { texts } from '../quiz';
import { envData } from '../quiz';

const { finish } = texts;
const { metrikNumber, endpoint } = envData;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
    width: '100%'
  },
  // color
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'var(--calc-sec-color)',
    },
    '& label': {
      color: 'var(--calc-text-color-light)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    //outlined

    '& .MuiOutlinedInput-root': {
      '& input': {
        color: 'var(--calc-main-color)'
      },
      '& fieldset': {
        borderColor: 'var(--calc-text-color-light)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--calc-sec-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--calc-sec-color)',
      },
    },
    // filled
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'var(--calc-text-color-light)',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'var(--calc-sec-color)',
    },
    '& .MuiFilledInput-root': {
      '& input': {
        color: 'var(--calc-main-color)',
        backgroundColor: 'var(--calc-text-color-dark)'
      },
      '& fieldset': {
        borderColor: 'var(--calc-text-color-light)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--calc-sec-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--calc-sec-color)',
      },
    },
  },
})(TextField);

export const Form = ({
  username,
  userphone,
  useremail,
  usernameChange,
  userphoneChange,
  useremailChange,
  status,
  statusChange,
  answers,
  question,
}) => {
  const classes = useStyles();

  const submitFunc = async (rctoken) => {
    statusChange('submitting');

    const order = `Ответы: ${answers[0].text} / ${answers[1].text} / ${answers[2].text}`;

    const url = endpoint;
    axios.post(url, {
      name: username,
      phone: userphone,
      email: useremail,
      token: rctoken,
      calc: order
    })
      // .then(r => console.log(JSON.stringify(r.data)))
      .then(r => setTimeout(() => statusChange('success'), 500))
      .then(r => window.ym(metrikNumber, 'reachGoal', 'REQUEST'));
  }

  const submitWithRecaptcha = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute(window.siteSecret, { action: 'submit' })
        .then(function (token) {
          // console.log(token);
          submitFunc(token)
        });
    });
  };

  const submitWithoutRecaptcha = (e) => {
    e.preventDefault();
    submitFunc('wo-recaptcha');
  };



  // const emailIsValid = (email) => {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // }

  const canProceed = username.length > 2 && userphone.length === 12;

  const currentButtonClass = cn({
    'submit_btn w-100 g-recaptcha': true,
    active: canProceed
  });

  const submitBtn = <span
    className={currentButtonClass}
    // type='submit'
    onClick={canProceed ? submitWithoutRecaptcha : null}>
    {finish.button}
  </span>;

  const form = (
    <form autoComplete="off" id="calc-form">
      {/* <ThemeProvider theme={darkTheme}> */}
      {/* <CssBaseline /> */}
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-4 col-sm-12 col-12">
            <CssTextField id="name-calc" label="Имя:" variant="filled" className={classes.margin} value={username} onChange={usernameChange(username, userphone)} />
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            <CssTextField label="Телефон:" variant="filled" className={classes.margin} value={userphone} onChange={userphoneChange(username, userphone)} />
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            {status !== 'success' && submitBtn}
          </div>
          {/* <CssTextField label="rcToken" id="rc_token" hidden={true} variant="outlined" value={token} className={classes.margin}  /> */}
        </div>
      </div>

      {/* <p>Укажите вашу электронную почту, мы вышлем туда готовый расчет.</p> */}
      {/* <TextField label="E-mail:" variant="filled" value={useremail} onChange={useremailChange} /> */}
      {/* <p className='disclaimer'>У специалиста могут возникнуть дополнительные вопросы,<br />укажите, пожалуйста, контактный телефон,<br />чтобы мы смогли созвониться с Вами.</p> */}
      {/* </ThemeProvider> */}
    </form>
  );


  const confirmation = <>
    <span className="h2 question-headline mt-3">{username}, {finish.success.grats}</span>
    <p className="calc-desc my-2">{finish.success.toPhone.firstLine}<br />{finish.success.toPhone.secondline}<br /><strong className="h2" style={{ color: 'var(--calc-main-color)' }}>{userphone}</strong>.</p>
    <p className='calc-desc lead my-3'>{finish.success.thanks.firstLine}<br />{finish.success.thanks.secondline}</p>
  </>;

  const submitting = <>
    <CircularProgress color="primary" size={100} thickness={1} classes={{ circle: 'main-color' }} />
    <span className="h2 question-headline mt-3">{username},<br />{finish.submitting}</span>
  </>;



  const showForm = status === 'pending';

  return (
    <div className='question px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-sm-5 px-3 py-3'>
      {status === 'submitting' && submitting}
      {status === 'pending' && <ProgressContainer />}
      {status === 'pending' && <span className="h2 question-headline">{question.name}</span>}
      {status === 'success' && confirmation}
      <p className="calc-desc">{status === 'pending' && question.description}</p>
      {showForm && form}
      {showForm && <span className='disclaimer small pt-3'>{finish.disclaimer}</span>}
    </div>


  );
};
